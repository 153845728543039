<script setup lang="ts">
const localePath = useLocalePath();
</script>

<template>
  <DashboardSideMenuDefault>
    <el-menu-item :index="localePath('patients')">
      <el-icon>
        <BaseIcon icon="flowbite:users-group-outline" size="20" />
      </el-icon>
      <template #title>
        {{ $t('dashboardPage.sidePanel.menu.patient.label') }}
      </template>
    </el-menu-item>
  </DashboardSideMenuDefault>
</template>

<style scoped></style>

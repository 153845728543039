<script setup lang="ts">
import { getUserInitial } from '~/utils/user';

const appStore = useAppStore();
const userStore = useUserStore();
const localePath = useLocalePath();
const router = useRouter();
</script>

<template>
  <el-menu
    class="h-full font-bold bg-primary flex flex-col"
    text-color="#fff"
    background-color="#1A1423"
    :default-active="router.currentRoute.value.fullPath"
    :collapse="appStore.isSidePanelCollapsed"
    router
  >
    <BaseLogo
      class="px-4 h-[50px] w-[150px] mb-4 mt-2"
      :no-typo="appStore.isSidePanelCollapsed"
    />
    <div class="flex-grow">
      <el-menu-item :index="localePath('/')">
        <el-icon>
          <BaseIcon icon="iconamoon:home-bold" size="20" />
        </el-icon>
        <template #title>
          {{ $t('dashboardPage.sidePanel.menu.home.label') }}
        </template>
      </el-menu-item>

      <el-menu-item :index="localePath('account')">
        <el-icon>
          <BaseIcon icon="iconamoon:profile-duotone" size="20" />
        </el-icon>
        <template #title>
          {{ $t('dashboardPage.sidePanel.menu.account.label') }}
        </template>
      </el-menu-item>
      <slot />
    </div>

    <div
      :class="[
        'footer',
        'flex',
        'items-center',
        'justify-center',
        'p-4',
        'gap-2',
        {
          'flex-col': appStore.isSidePanelCollapsed,
          'flex-row-reverse': !appStore.isSidePanelCollapsed,
        },
      ]"
    >
      <div class="flex items-center gap-2">
        <el-avatar
          class="cursor-pointer"
          @click="navigateTo(localePath('account'))"
        >
          {{ getUserInitial(userStore.currentUser) }}
        </el-avatar>
        <div
          v-if="!appStore.isSidePanelCollapsed"
          class="user flex gap-2 items-center"
        >
          <div class="flex flex-col text-white text-[12px]">
            <strong>
              {{ userStore.currentUser.firstname }}
              {{ userStore.currentUser.lastname }}
            </strong>
            <span class="text-gray-600">{{ userStore.currentUser.email }}</span>
          </div>
        </div>
      </div>

      <BaseIcon
        class="text-white"
        size="20"
        :icon="`material-symbols:${appStore.isSidePanelCollapsed ? 'left' : 'right'}-panel-open-outline-rounded`"
        @click="appStore.toggleSidePanel()"
      ></BaseIcon>
    </div>
  </el-menu>
</template>

<style>
.el-menu-item {
  @apply gap-3 cursor-pointer;
}
.el-menu-item.is-active {
  background-color: var(--el-menu-hover-bg-color);
  -webkit-box-shadow: inset 4px 0 0 0 #fff;
  box-shadow: inset 4px 0 0 0 #fff;
  @apply text-white;
}
</style>

export default defineNuxtRouteMiddleware(async (to, from) => {
  const onboardingStore = useOnboardingStore();

  const localePath = useLocalePath();

  if (to.path.includes('auth')) {
    return;
  } else {
    // escape if already on onboarding route
    if (to.path.includes('onboarding')) {
      if (onboardingStore.isInOnboardingProcess) {
        return;
      } else {
        return navigateTo(localePath('/'));
      }
    }

    if (onboardingStore.isInOnboardingProcess) {
      return navigateTo(localePath('/onboarding'));
    }
  }
  return;
});

import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/vercel/path0/middleware/01.auth.global.ts";
import _02_45clean_45query_45params_45global from "/vercel/path0/middleware/02.cleanQueryParams.global.ts";
import _03_45onboarding_45global from "/vercel/path0/middleware/03.onboarding.global.ts";
import guard_45backoffice_45global from "/vercel/path0/middleware/guard-backoffice.global.ts";
import guard_45patients_45global from "/vercel/path0/middleware/guard-patients.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45clean_45query_45params_45global,
  _03_45onboarding_45global,
  guard_45backoffice_45global,
  guard_45patients_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "user-patient-guard": () => import("/vercel/path0/middleware/user-patient-guard.ts")
}
export enum AvailableModal {
  NutrientUpdate = 'NutrientUpdate',
  FoodUpsert = 'FoodUpsert',
  FoodFamilyUpdate = 'FoodFamilyUpdate',
  PatientUpsert = 'PatientUpsert',
  FoodSurveyFoodUpsert = 'FoodSurveyFoodUpsert',
  PortionUpsert = 'PortionUpsert',
  AddPortionToFood = 'AddPortionToFood',
  UpdateDeletePortionFromFood = 'UpdateDeletePortionFromFood',
  AddIngredientToFood = 'AddIngredientToFood',
  UpdateDeleteIngredientFromFood = 'UpdateDeleteIngredientFromFood',
  Confirm = 'Confirm',
}

export const useModalStore = defineStore('modal', () => {
  const activeModal = ref();
  const activeModalProps = ref();
  const isModalOpen = ref(false);
  const activeModalComponent = computed(() =>
    activeModal.value
      ? defineAsyncComponent(
          () => import(`~/components/modal/${activeModal.value}.vue`),
        )
      : null,
  );

  async function openModal(
    modalName: AvailableModal,
    modalProps?: Record<string, unknown>,
  ) {
    activeModal.value = modalName;
    activeModalProps.value = modalProps;
    isModalOpen.value = true;
  }

  async function closeModal() {
    isModalOpen.value = false;
    setTimeout(() => {
      activeModal.value = null;
      activeModalProps.value = null;
    }, 100);
  }

  return {
    AvailableModal,
    isModalOpen,
    activeModalComponent,
    activeModalProps,
    openModal,
    closeModal,
  };
});

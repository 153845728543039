export default defineNuxtRouteMiddleware(async (to, from) => {
  const localePath = useLocalePath();
  const userStore = useUserStore();
  const patientStore = usePatientStore();

  // User Patients Routes
  if (to.path.includes('patients') && userStore.currentUser?.role?.id !== 1) {
    return navigateTo(localePath('/'));
  }

  const patientId = to.params.patientId;
  if (!patientId) {
    patientStore.setActivePatient(undefined);
  } else if (patientId !== patientStore.activePatient?.id) {
    await patientStore.fetchDetailedPatient(patientId);
    if (!patientStore.activePatient?.id) {
      return navigateTo(localePath('patients'));
    }
  }
});

<script lang="ts" setup>
import {
  DashboardSideMenuBackoffice,
  DashboardSideMenuNutritian,
} from '#components';

const appStore = useAppStore();
const modalStore = useModalStore();
const userStore = useUserStore();

userStore.fetchCurrentUser();

const panelComponent = {
  0: DashboardSideMenuBackoffice,
  1: DashboardSideMenuNutritian,
};
</script>
<template>
  <div class="flex h-screen w-full">
    <component
      :is="modalStore.activeModalComponent"
      v-bind="modalStore.activeModalProps"
    />
    <component :is="panelComponent[userStore.currentUser?.role.id]" />
    <div
      :class="[
        'flex',
        'flex-col',
        {
          'w-full': userStore.isPatientUser,
          'w-[calc(100%-65px)]':
            !userStore.isPatientUser && appStore.isSidePanelCollapsed,
          'w-[calc(100%-275px)]':
            !userStore.isPatientUser && !appStore.isSidePanelCollapsed,
        },
      ]"
    >
      <NuxtPage />
    </div>
  </div>
</template>

export const useOnboardingStore = defineStore('onboarding', () => {
  const $api = useApi();

  const userStore = useUserStore();
  const localePath = useLocalePath();
  const { setLoadingPending, setLoadingSuccess, setLoadingFailed } =
    useLoading();
  const { $i18n } = useNuxtApp();

  const isInOnboardingProcess = computed(
    () => !userStore.currentUser?.isOnboarded,
  );

  const onboardedUserInfos = reactive({
    firstname: userStore.currentUser?.firstname ?? '',
    lastname: userStore.currentUser?.lastname ?? '',
  });

  const onboardedUserNewPassword = ref('ok');

  const onboardedPatientInfos = reactive({
    isVegetarian: false,
    isVegan: false,
    isPorkFree: false,
    isFishFree: false,
    isSugarFree: false,
    isGlutenFree: false,
    isLactoseFree: false,
    height: 0,
    weight: 0,
    sleep: 0,
    notes: '',
  });

  async function updateOnboardedUser() {
    setLoadingPending();
    try {
      const { email, website, avatar_url } = userStore.currentUser;
      await userStore.updateCurrentUser({
        firstname: onboardedUserInfos.firstname,
        lastname: onboardedUserInfos.lastname,
        email,
        website,
        avatar_url,
        lang: $i18n.locale.value,
      });
      setLoadingSuccess();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoadingFailed();
    }
  }

  async function updateOnboardedUserPassword() {
    setLoadingPending();
    try {
      await $api.post('/user/setPassword', {
        newPassword: onboardedUserNewPassword.value,
      });
      setLoadingSuccess();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoadingFailed();
    }
  }

  async function updateOnboardedPatient() {
    setLoadingPending();
    try {
      await $api.post('/user/patientInfos', onboardedPatientInfos);
      setLoadingSuccess();
    } catch (error) {
      alert(error.message);
    } finally {
      setLoadingFailed();
    }
  }

  async function saveAndStart() {
    await $api.post('/user/onboarding', {
      userId: userStore.currentUser.id,
      isOnboarded: true,
    });
    await userStore.fetchCurrentUser();
    navigateTo(localePath('/'));
  }
  return {
    onboardedUserInfos,
    onboardedPatientInfos,
    onboardedUserNewPassword,
    isInOnboardingProcess,
    saveAndStart,
    updateOnboardedUser,
    updateOnboardedUserPassword,
    updateOnboardedPatient,
  };
});

export default defineNuxtRouteMiddleware((to, from) => {
  const localePath = useLocalePath();
  const { auth_token, auth_type, ...otherQueryParams } = to.query;
  if (auth_token && auth_type) {
    return navigateTo({
      ...to,
      path: localePath(to.path),
      query: otherQueryParams,
    });
  }
  return;
});

export const useAppStore = defineStore('app', () => {
  const isSidePanelCollapsed = ref(
    JSON.parse(localStorage.getItem('isSidePanelCollapsed')) || false,
  );
  function toggleSidePanel() {
    isSidePanelCollapsed.value = !isSidePanelCollapsed.value;
    localStorage.setItem(
      'isSidePanelCollapsed',
      JSON.stringify(isSidePanelCollapsed.value),
    );
  }

  return {
    isSidePanelCollapsed,
    toggleSidePanel,
  };
});

<script setup>
import { Icon } from '#components';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  size: {
    type: String,
  },
});
</script>
<template>
  <Icon :name="props.icon" :size="props.size" />
</template>

import { useAlertStore } from '~/stores/alert';

const API_ENDPOINT = '/api/v1';

enum FetchMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

type PerformApiCall = {
  method: FetchMethods;
  endpoint: string;
  headers?: Record<string, string>;
  data?: Record<string, string>;
};

export default function useApi() {
  const { isLoading, setLoadingPending, setLoadingFailed, setLoadingSuccess } =
    useLoading();

  const localePath = useLocalePath();
  const alertStore = useAlertStore();

  async function performApiCall({
    method,
    endpoint,
    headers,
    data,
  }: PerformApiCall): Promise<{
    data: Record<string, string> | null;
    error?: Record<string, string> | null;
  }> {
    try {
      const result = await $fetch(`${API_ENDPOINT}${endpoint}`, {
        method,
        headers,
        body: data,
      });
      if (result.error) {
        throw new Error(result.error.message);
      }
      return result;
    } catch (e) {
      // TODO implement logger (Sentry)
      if ([401, 403].includes(e.statusCode)) {
        // TODO implement logger warning (Sentry)
        return navigateTo(localePath('/auth'));
      } else {
        alertStore.error(`Api Error - ${e.message}`);
      }
      return { error: e };
    }
  }

  async function get(endpoint: string) {
    return await performApiCall({ method: FetchMethods.Get, endpoint });
  }

  async function post(endpoint: string, data?: Record<string, string>) {
    return await performApiCall({
      method: FetchMethods.Post,
      endpoint,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async function put(endpoint: string, data?: Record<string, string>) {
    return await performApiCall({
      method: FetchMethods.Put,
      endpoint,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async function remove(endpoint: string, data?: Record<string, string>) {
    return await performApiCall({
      method: FetchMethods.Delete,
      endpoint,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  return { isApiLoading: isLoading, get, post, put, delete: remove };
}

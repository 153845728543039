export default defineNuxtRouteMiddleware(async (to, from) => {
  const supabase = useSupabaseClient();
  const session = useSupabaseSession();

  const userStore = useUserStore();
  const localePath = useLocalePath();
  const { $i18n } = useNuxtApp();

  if (session.value) {
    if (!userStore.currentUser) {
      await userStore.fetchCurrentUser();
      if (
        userStore.currentUser?.lang?.iso_code &&
        userStore.currentUser?.lang?.iso_code !== $i18n.locale
      ) {
        $i18n.setLocale(userStore.currentUser.lang.iso_code);
      }
      return;
    }

    // trying to reach /auth where already logged in...
    if (to.path.includes('/auth')) {
      return navigateTo(localePath('/'));
    }
  } else {
    const { auth_token, auth_type, ...otherQueryParams } = to.query;

    // Token Hash
    if (auth_token && auth_type) {
      const { error } = await supabase.auth.verifyOtp({
        token_hash: auth_token,
        type: auth_type,
      });

      if (error) {
        return navigateTo(localePath('/auth'));
      }
      await userStore.fetchCurrentUser();
      return;
    }

    // ... Unsuccessful authentication
    if (!to.path.includes('/auth')) {
      return navigateTo(localePath('/auth'));
    }
  }
});

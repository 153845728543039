import type { User } from '@supabase/gotrue-js';
import type { Ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const supabase = useSupabaseClient();
  const $api = useApi();

  const currentUser: Ref<User | null> = ref(null);

  const currentUserNutrician = ref(null);

  const isAdmin = computed(() => currentUser.value?.isAdmin);
  const isLoggedIn = computed(() => !!currentUser.value);
  const isPatientUser = computed(
    () => currentUser.value?.role?.label === 'app-patient',
  );

  function setCurrentUser(user: User | null) {
    currentUser.value = user;
  }

  function clearCurrentUser() {
    setCurrentUser(null);
    supabase.auth.signOut();
  }

  async function fetchCurrentUser() {
    const { data: me } = await $api.get('/user/me');
    setCurrentUser(me);
    if (isPatientUser.value) {
      fetchCurrentUserNutrician();
    }
  }

  async function fetchCurrentUserNutrician() {
    const { data: nutrician } = await $api.get('/user/nutrician');
    currentUserNutrician.value = nutrician;
  }

  async function updateCurrentUser(data: {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
    website: string;
    avatar_url: string;
    lang: string;
  }) {
    const { data: updatedMe } = await $api.put('/user/me', data);
    setCurrentUser(updatedMe);
  }

  return {
    currentUser,
    currentUserNutrician,
    fetchCurrentUser,
    updateCurrentUser,
    clearCurrentUser,
    isAdmin,
    isLoggedIn,
    isPatientUser,
  };
});

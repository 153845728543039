import { ElMessage } from 'element-plus';

enum AlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export const useAlertStore = defineStore('alert', () => {
  const DURATION = 2000;

  function showAlert(type: AlertType, message: string) {
    ElMessage({
      type,
      message,
      duration: type === AlertType.Error ? 0 : DURATION,
      showClose: type === AlertType.Error,
    });
  }

  return {
    info: (message) => showAlert(AlertType.Info, message),
    success: (message) => showAlert(AlertType.Success, message),
    warning: (message) => showAlert(AlertType.Warning, message),
    error: (message) => showAlert(AlertType.Error, message),
  };
});
